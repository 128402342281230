function setEqualHeight() {
    $(".grid-product header").matchHeight();
    $(".grid-product .product-options").matchHeight();
    $(".grid-product .productshortdesc").matchHeight();
    $(".grid-product .productshortattributes").matchHeight();
    $(".news-image-wrapper, .news-content-wrapper").matchHeight();
}
function setEqualHightRow(row){
    row.find(".grid-product header").matchHeight();
    row.find(".grid-product .product-options").matchHeight();
    row.find(".grid-product .productshortdesc").matchHeight();
    row.find(".grid-product .productshortattributes").matchHeight();
    row.find(".news-image-wrapper, .news-content-wrapper").matchHeight();
}

$(window).on("load", function() {
    setEqualHeight();
});
$(window).on("resize", function() {
    // setEqualHeight();
    $.fn.matchHeight._update();
});

function updateProductListHeight() {
    $.fn.matchHeight._update();
}

$('#main-menu-state').change(function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $('#main-menu').css("max-height", maxHeight + "px");
                if ($('#main-menu').outerHeight() > maxHeight) {
                    $('#main-menu').css("height", maxHeight + "px");
                }
            }
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $('#main-menu').css("max-height", "");
            $('#main-menu').css("height", "");
        });
    }
});

$(function() {
    $("#main-menu-mobile").smartmenus({
        subMenusSubOffsetX: 1,
        subMenusSubOffsetY: -8,
        keepInViewport: true,
        keepHighlighted: false,
        markCurrentItem: false,
        markCurrentTree: false
        /*}).bind("click.smapi", function(e, item) {
            var $item = $(item);
            if($item.attr("href") === "#") {
                e.preventDefault();
                $('#main-menu').smartmenus('itemActivate', $item);
                return false;
            }*/
    });
});

$('#main-menu-state-mobile').change(function(e) {
    var $menu = $('#main-menu-mobile');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu-mobile").offset().top - $(window).scrollTop()));
                $('#main-menu-mobile').css("max-height", maxHeight + "px");
                if ($('#main-menu-mobile').outerHeight() > maxHeight) {
                    $('#main-menu-mobile').css("height", maxHeight + "px");
                }
            }
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $('#main-menu-mobile').css("max-height", "");
            $('#main-menu-mobile').css("height", "");
        });
    }
});

$(window).bind('scroll', function() {
    var current_breakpoint = getSkelSize(),
        current_breakpoint_num = getNumSkelSize(),
        scroll_limit_1 = 46,
        scroll_limit = 96,
        initial_height = 130,
        target_height = 80,
        initial_padding = 35,
        target_padding = 10,
        initial_margin_top = 0,
        target_margin_top = -46,
        margin,
        logo_height,
        padding,
        line_height;
    switch (current_breakpoint) {
        case "xxxlarge":
            scroll_limit_1 = 46;
            scroll_limit = 96;
            initial_height = 130;
            target_height = 80;
            initial_padding = 35;
            target_padding = 10;
            initial_margin_top = 0;
            target_margin_top = -46;
            break;
        case "xxlarge":
            scroll_limit_1 = 46;
            scroll_limit = 96;
            initial_height = 130;
            target_height = 80;
            initial_padding = 35;
            target_padding = 10;
            initial_margin_top = 0;
            target_margin_top = -46;
            break;
        case "large":
            scroll_limit_1 = 46;
            scroll_limit = 96;
            initial_height = 130;
            target_height = 80;
            initial_padding = 35;
            target_padding = 10;
            initial_margin_top = 0;
            target_margin_top = -46;
            break;
        case "medium":
            scroll_limit_1 = 40;
            scroll_limit = 40;
            initial_height = 60;
            target_height = 60;
            initial_padding = 5;
            target_padding = 5;
            initial_margin_top = 0;
            target_margin_top = -40;
            break;
        case "small":
            scroll_limit_1 = 40;
            scroll_limit = 40;
            initial_height = 60;
            target_height = 60;
            initial_padding = 5;
            target_padding = 5;
            initial_margin_top = 0;
            target_margin_top = -40;
            break;
        case "xsmall":
            scroll_limit_1 = 40;
            scroll_limit = 40;
            initial_height = 60;
            target_height = 60;
            initial_padding = 5;
            target_padding = 5;
            initial_margin_top = 0;
            target_margin_top = -40;
            break;
        default:
            scroll_limit_1 = 46;
            scroll_limit = 96;
            initial_height = 130;
            target_height = 80;
            initial_padding = 10;
            target_padding = 10;
            initial_margin_top = 0;
            target_margin_top = -46;
            break;
    }
    if (current_breakpoint_num > 3) {
        if ($(window).scrollTop() === 0) {
            $("#header-wrapper").css("margin-top", "");
            margin_top = initial_margin_top
            padding = initial_padding;
            logo_height = initial_height;
        } else if ($(window).scrollTop() > 0 && $(window).scrollTop() <= scroll_limit_1) {
            $("#header-wrapper").css("margin-top", -$(window).scrollTop() + "px");
            margin_top = initial_margin_top - $(window).scrollTop();
            padding = initial_padding;
            logo_height = initial_height;
        } else if ($(window).scrollTop() > scroll_limit_1 && $(window).scrollTop() <= scroll_limit) {
            $("#header-wrapper").css("margin-top", -scroll_limit_1 + "px");
            margin_top = target_margin_top;
            padding = initial_padding * ((scroll_limit - scroll_limit_1) - ($(window).scrollTop() - scroll_limit_1)) / (scroll_limit - scroll_limit_1);
            logo_height = initial_height - (initial_height - target_height) * ($(window).scrollTop() - scroll_limit_1) / (scroll_limit - scroll_limit_1);
            /*
            if (current_breakpoint_num > 3) {
                $("#logo-top").show();
                $("#logo-scroll").hide();
            }
            */
        } else {
            $("#header-wrapper").css("margin-top", -scroll_limit_1 + "px");
            margin_top = target_margin_top;
            padding = target_padding;
            logo_height = target_height;
            /*
            if (current_breakpoint_num > 3) {
                $("#logo-top").hide();
                $("#logo-scroll").show();
            }
            */
        }
        $("#logo a").css("padding-top", padding + "px");
        $("#logo a").css("padding-bottom", padding + "px");
        $("#logo a").css("height", logo_height + "px");
        // $("#header-main-wrapper").css("padding-top", padding + "px");
        // $("#header-main-wrapper").css("padding-bottom", padding + "px");
    } else {
        $("#logo a").css("padding-top", "");
        $("#logo a").css("padding-bottom", "");
        $("#logo a").css("height", "");
        if ($(window).scrollTop() === 0) {
            $("#header-wrapper").css("margin-top", "");
        } else if ($(window).scrollTop() > 0 && $(window).scrollTop() <= scroll_limit_1) {
            $("#header-wrapper").css("margin-top", -$(window).scrollTop() + "px");
        } else if ($(window).scrollTop() > scroll_limit_1 && $(window).scrollTop() <= scroll_limit) {
            $("#header-wrapper").css("margin-top", -scroll_limit_1 + "px");

        } else {
            $("#header-wrapper").css("margin-top", -scroll_limit_1 + "px");
        }
        // $("#header-main-wrapper").css("padding-top", "");
        // $("#header-main-wrapper").css("padding-bottom", "");
    }

});
